import React, { Suspense } from "react";
import { toast } from "react-toastify";
import { Provider } from "react-redux";
import Store from "./store";
import { BrowserRouter as Router, Route } from "react-router-dom";
import RouterContainer from "./router/routerContainer";
import { Online, Offline } from "react-detect-offline";
import Network from "./screen/offline/index";

toast.configure({
  autoClose: 3000,
  draggable: false,
  newestOnTop: true,
  position: "top-right",
  closeOnClick: true,
});
const App: React.FC = () => {

  return (
    <div className="root-container">
        <Provider store={Store}>
          <Suspense fallback={""}>
            <RouterContainer />
          </Suspense>
        </Provider>
    </div>
  );
};

export default App;
