const LocalImages = {
  LOGIN_BG_IMG: "../images/login_background.png",
  BRAND_LOGO: "../images/logo.svg",
  FORGOT_PASS_LOCK_IMG: "../images/Lock.svg",
  PASSWORD_HIDE: "../images/hide.svg",
  PASSWORD_SHOW: "../images/unhide.svg",
  HEADER_MENU: "../images/sidenav-toggle.svg",
  HEADER_PROFILE_PLACEHOLDER: "../images/profile_placeholder.svg",
  NOTIFICATION_DARK: "../images/notification_with_dot_dark.svg",
  NOTIFICATION_DOT: "../images/notification_with_dot.svg",
  NOTIFICATION: "../images/notification_without_dot.svg",
  NOTIFICATION_WDOT: "../images/notification_without_dot_dark.svg",
  NAV_STUDY_PLANNER: "../images/study_planner.svg",
  NAV_STUDY_PLANNER_DARK: "../images/study_planner_dark.svg",
  NAV_MY_PROGRESS_PLANNER: "../images/my_progress.svg",
  NAV_MY_PROGRESS_PLANNER_DARK: "../images/my_progress_dark.svg",
  NAV_TIMETABLE_PLANNER: "../images/timetable.svg",
  NAV_PRACTICE: "../images/practice.svg",
  NAV_PRACTICE_DARK: "../images/practice_dark.svg",
  NAV_RESOURCE: "../images/resources.svg",
  NAV_MY_TEMPLATE: "../images/my_template.svg",
  NAV_SETTINGS: "../images/settings.svg",
  NAV_SETTINGS_DARK: "../images/settings_dark.svg",
  DROPDOWN: "../images/drop_down.svg",
  SEARCH: "../images/search.svg",
  PUSHPIN: "../images/pushpin.svg",
  PUSHPIN_SAVED: "../images/pushpinsaved.svg",
  CALENDER: "../images/icons-basic-calendar.svg",
  FILTER: "../images/icons-basic-filter.svg",
  IMPORVEMENT_ON_RETRY: "../images/improvement-on-retry.svg",
  QUESTION_DONE: "../images/questions-done.svg",
  RETRIES: "../images/retries.svg",
  SELF_ASSESSMENT: "../images/self-assesments.svg",
  TEMPLATE_CREATED: "../images/template created.svg",
  TIME_QUESTIONS: "../images/timer-questions.svg",
  PAUSE: "../images/pause.svg",
  PLAY: "../images/play.svg",
  INFO: "../images/info.svg",
  RIGHT_ARROW: "../images/right-arrow.svg",
  FLAG: "../images/flag_question.svg",
  FLAG_FILLED_WHITE: "../images/flag_white.svg",
  EXPORT: "../images/export.svg",
  ADD_TEMPLATE: "../images/add-template.svg",
  CLOCK_ICON: "../images/icons-basic-time.svg",
  TEXT_ALIGN: "../images/icons-text-align-left.svg",
  INACTIVE_MARKER: "../images/marker1.svg",
  SELF_ASSESSMENT_PENCIL: "../images/self_assessment_pencil.svg",
  SELF_ASSESSMENT_PENCIL_SELECTED:"../images/self_assessment_pencil_selected.svg",
  RIGHT_ARROW_ONE: "../images/white-left-arrow.svg",
  LEFT_ARROW_ONE: "../images/white_arrow_right.svg",
  AVG_INFO: "../images/Avg_info.svg",
  Path:"../images/Path.png",
  Shape:"../images/Shape.png"

};
export default LocalImages;
