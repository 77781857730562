import api from "./api";
import showAlert from "./alert";
import endPoints from "./endPoints";
import ActionName from "./actionName";
import Pathname from "./pathname";
import constants from "./constants";

const Utils = {
  api: api,
  showAlert: showAlert,
  endPoints: endPoints,
  ActionName: ActionName,
  Pathname: Pathname,
  constants: constants,
};

export default Utils;
