const endPoint = {
  login: "/user/login",
  addTask: "/user/task",
  logout: "/user/logout",
  series: "/user/series",
  getTasks: "/user/tasks",
  profile: "/user/profile",
  markAsRead: "/user/read",
  view: "/user/course/view",
  endTask: "/user/end-task",
  subjects: "/user/subjects",
  question: "/user/questions",
  resources: "/user/resources",
  weeks: "/user/session/weeks",
  startTask: "/user/start-task",
  categories: "/user/categories",
  overdue: "/user/overdue-tasks",
  savecourse: "/user/save-course",
  refresh: "/common/refresh-token",
  questions: "/user/questions/list",
  checklist: "/user/tasks/checklist",
  welcomePopup: "/user/welcome-popup",
  unsavecourse: "/user/unsave-course",
  flagQuestion: "/user/question/flag",
  resourcesList: "/user/resources/list",
  submitAnswer: "/user/question/answere",
  selfMark: "/user/question/self-mark",
  forgotPassword: "/user/forgot-password",
  changepassword: "/user/change-password",
  resetpassword: "/common/change-forgot-password",
  getTemplates: "/user/template/list",
  createAndEditTemplate: "/user/template",
  deleteTemplate: "/user/templates",
  exportTemplate: "user/template/export",
  timeTable: "/time-table/user",
  dashboard: "/user/dashboard",
  topics: "/user/topics",
  notification: "/user/notification",
  examResult: "/user/result",
  markingTutoial: "/user/marking-tutorial",
  questionDetails: "/user/questions-detail",
};
export default endPoint;
