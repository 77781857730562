import Utils from "./index";
// @ts-ignore
import htmlToDraft from "html-to-draftjs";
// @ts-ignore
import draftToHtml from "draftjs-to-html";
import { ContentState, EditorState, convertToRaw } from "draft-js";

const getMonth = (month: string) => {
  switch (month) {
    case "01":
      return "Jan";
    case "02":
      return "Feb";
    case "03":
      return "Mar";
    case "04":
      return "April";
    case "05":
      return "May";
    case "06":
      return "June";
    case "07":
      return "July";
    case "08":
      return "Aug";
    case "09":
      return "Sep";
    case "10":
      return "Oct";
    case "11":
      return "Nov";
    case "12":
      return "Dec";
  }
};
const getDate = (date: string) => {
  if (date === "") return "";
  let date1 = date.split("-");
  let year = date1[0];
  let month = getMonth(date1[1]);
  let day = date1[2].split("T")[0];
  return `${day} ${month}, ${year}`;
};
const setFilterData = () => {
  if (localStorage.getItem("rememberMe") === "yes") {
    let initialFilterData: any = localStorage.getItem("filter");
    return JSON.parse(initialFilterData);
  } else if (localStorage.getItem("rememberMe") === "no") {
    let initialFilterData1: any = sessionStorage.getItem("filter");
    return JSON.parse(initialFilterData1);
  }
};

const getTimeStamp = () => {
  const currentDate: any = new Date();
  return Date.parse(currentDate);
};

const getSubjectId = (subject: string, subjectData: any) => {
  const subjectInfo = subjectData?.find(
    (value: any) => value.subject === subject
  );
  return subjectInfo?._id;
};
const getCategoryId = (topic: string, categoryData: any) => {
  const categoryInfo = categoryData?.find(
    (value: any) => value.category === topic
  );
  return categoryInfo?._id;
};
const getSeriesId = (series: string, seriesData: any) => {
  const seriesInfo = seriesData?.find((value: any) => value.title === series);
  return seriesInfo?._id;
};
const getUrl = (
  allSubjects: string,
  allTopics: string,
  allCategory: string,
  allSeries: string,
  answered: string,
  subjectData: any,
  categoryData: any,
  allSeriesData: any,
  week: number,
  page: number
) => {
  if (week === 0) {
    if (
      allSubjects === "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory === "All Categories" &&
      allSeries === "All Series" &&
      answered === "Answer Type"
    ) {
      return `?`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory === "All Categories" &&
      allSeries === "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      return `?subjectId=${subjectId}`;
    } else if (
      allSubjects === "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries === "All Series" &&
      answered === "Answer Type"
    ) {
      let priority = allCategory;
      return `?priority=${priority}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory === "All Categories" &&
      allSeries === "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      return `?subjectId=${subjectId}&categoryId=${categoryId}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries === "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let priority = allCategory;
      return `?subjectId=${subjectId}&priority=${priority}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory === "All Categories" &&
      allSeries !== "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let seriesId = getSeriesId(allSeries, allSeriesData);
      return `?subjectId=${subjectId}&seriesId=${seriesId}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory === "All Categories" &&
      allSeries === "All Series" &&
      answered !== "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let answer = answered === "Not answered" ? false : true;
      return `?subjectId=${subjectId}&isAnswered=${answer}`;
    } else if (
      allSubjects === "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries === "All Series" &&
      answered !== "Answer Type"
    ) {
      let priority = allCategory;
      let answer = answered === "Not answered" ? false : true;
      return `?piority=${priority}&isAnswered=${answer}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries === "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      let priority = allCategory;
      return `?subjectId=${subjectId}&categoryId=${categoryId}&priority=${priority}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory === "All Categories" &&
      allSeries !== "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      let seriesId = getSeriesId(allSeries, allSeriesData);
      return `?subjectId=${subjectId}&categoryId=${categoryId}&seriesId=${seriesId}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory === "All Categories" &&
      allSeries === "All Series" &&
      answered !== "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      let answer = answered === "Not answered" ? false : true;
      return `?subjectId=${subjectId}&categoryId=${categoryId}&isAnswered=${answer}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries !== "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let priority = allCategory;
      let seriesId = getSeriesId(allSeries, allSeriesData);
      return `?subjectId=${subjectId}&priority=${priority}&seriesId=${seriesId}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries === "All Series" &&
      answered !== "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let priority = allCategory;
      let answer = answered === "Not answered" ? false : true;
      return `?subjectId=${subjectId}&priority=${priority}&isAnswered=${answer}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory === "All Categories" &&
      allSeries !== "All Series" &&
      answered !== "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let seriesId = getSeriesId(allSeries, allSeriesData);
      let answer = answered === "Not answered" ? false : true;
      return `?subjectId=${subjectId}&seriesId=${seriesId}&isAnswered=${answer}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries !== "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      let priority = allCategory;
      let seriesId = getSeriesId(allSeries, allSeriesData);
      return `?subjectId=${subjectId}&categoryId=${categoryId}&priority=${priority}&seriesId=${seriesId}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries === "All Series" &&
      answered !== "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      let priority = allCategory;
      let answer = answered === "Not answered" ? false : true;
      return `?subjectId=${subjectId}&categoryId=${categoryId}&priority=${priority}&isAnswered=${answer}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries !== "All Series" &&
      answered !== "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      let seriesId = getSeriesId(allSeries, allSeriesData);
      let priority = allCategory;
      let answer = answered === "Not answered" ? false : true;
      return `?subjectId=${subjectId}&categoryId=${categoryId}&priority=${priority}&seriesId=${seriesId}&isAnswered=${answer}`;
    } else if (
      allSubjects === "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory === "All Categories" &&
      allSeries === "All Series" &&
      answered === "Answer Type"
    ) {
      let categoryId = getCategoryId(allTopics, categoryData);

      return `?categoryId=${categoryId}`;
    } else if (
      allSubjects === "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries === "All Series" &&
      answered === "Answer Type"
    ) {
      let categoryId = getCategoryId(allTopics, categoryData);
      let priority = allCategory;
      return `?categoryId=${categoryId}&priority=${priority}`;
    } else if (
      allSubjects === "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries !== "All Series" &&
      answered === "Answer Type"
    ) {
      let categoryId = getCategoryId(allTopics, categoryData);
      let seriesId = getSeriesId(allSeries, allSeriesData);
      let priority = allCategory;
      return `?categoryId=${categoryId}&priority=${priority}&seriesId=${seriesId}`;
    } else if (
      allSubjects === "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries !== "All Series" &&
      answered === "Answer Type"
    ) {
      let seriesId = getSeriesId(allSeries, allSeriesData);
      let priority = allCategory;

      return `?priority=${priority}&seriesId=${seriesId}`;
    } else if (
      allSubjects === "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries !== "All Series" &&
      answered !== "Answer Type"
    ) {
      let seriesId = getSeriesId(allSeries, allSeriesData);
      let priority = allCategory;
      let answer = answered === "Not answered" ? false : true;

      return `?priority=${priority}&seriesId=${seriesId}&isAnswered=${answer}`;
    } else if (
      allSubjects === "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory === "All Categories" &&
      allSeries === "All Series" &&
      answered !== "Answer Type"
    ) {
      let answer = answered === "Not answered" ? false : true;
      return `?isAnswered=${answer}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory === "All Categories" &&
      allSeries !== "All Series" &&
      answered !== "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      let seriesId = getSeriesId(allSeries, allSeriesData);
      let answer = answered === "Not answered" ? false : true;
      return `?subjectId=${subjectId}&categoryId=${categoryId}&seriesId=${seriesId}&isAnswered=${answer}`;
    } else if (
      allSubjects === "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory === "All Categories" &&
      allSeries !== "All Series" &&
      answered === "Answer Type"
    ) {
      let seriesId = getSeriesId(allSeries, allSeriesData);
      return `?seriesId=${seriesId}`;
    }
  } else {
    if (
      allSubjects === "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory === "All Categories" &&
      allSeries === "All Series" &&
      answered === "Answer Type"
    ) {
      return `?week=${week}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory === "All Categories" &&
      allSeries === "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      return `?subjectId=${subjectId}&week=${week}`;
    } else if (
      allSubjects === "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries === "All Series" &&
      answered === "Answer Type"
    ) {
      let priority = allCategory;
      return `?priority=${priority}&week=${week}`;
    } else if (
      allSubjects === "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory === "All Categories" &&
      allSeries !== "All Series" &&
      answered === "Answer Type"
    ) {
      let seriesId = getSeriesId(allSeries, allSeriesData);
      return `?seriesId=${seriesId}&week=${week}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory === "All Categories" &&
      allSeries === "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      return `?subjectId=${subjectId}&categoryId=${categoryId}&week=${week}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries === "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let priority = allCategory;
      return `?subjectId=${subjectId}&priority=${priority}&week=${week}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory === "All Categories" &&
      allSeries !== "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let seriesId = getSeriesId(allSeries, allSeriesData);
      return `?subjectId=${subjectId}&seriesId=${seriesId}&week=${week}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory === "All Categories" &&
      allSeries === "All Series" &&
      answered !== "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let answer = answered === "Not answered" ? false : true;
      return `?subjectId=${subjectId}&isAnswered=${answer}&week=${week}`;
    } else if (
      allSubjects === "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries === "All Series" &&
      answered !== "Answer Type"
    ) {
      let priority = allCategory;
      let answer = answered === "Not answered" ? false : true;
      return `?piority=${priority}&isAnswered=${answer}&week=${week}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries === "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      let priority = allCategory;
      return `?subjectId=${subjectId}&categoryId=${categoryId}&priority=${priority}&week=${week}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory === "All Categories" &&
      allSeries !== "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      let seriesId = getSeriesId(allSeries, allSeriesData);
      return `?subjectId=${subjectId}&categoryId=${categoryId}&seriesId=${seriesId}&week=${week}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory === "All Categories" &&
      allSeries === "All Series" &&
      answered !== "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      let answer = answered === "Not answered" ? false : true;
      return `?subjectId=${subjectId}&categoryId=${categoryId}&isAnswered=${answer}&week=${week}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries !== "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let priority = allCategory;
      let seriesId = getSeriesId(allSeries, allSeriesData);
      return `?subjectId=${subjectId}&priority=${priority}&seriesId=${seriesId}&week=${week}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries === "All Series" &&
      answered !== "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let priority = allCategory;
      let answer = answered === "Not answered" ? false : true;
      return `?subjectId=${subjectId}&priority=${priority}&isAnswered=${answer}&week=${week}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory === "All Categories" &&
      allSeries !== "All Series" &&
      answered !== "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let seriesId = getSeriesId(allSeries, allSeriesData);
      let answer = answered === "Not answered" ? false : true;
      return `?subjectId=${subjectId}&seriesId=${seriesId}&isAnswered=${answer}&week=${week}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries !== "All Series" &&
      answered === "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      let priority = allCategory;
      let seriesId = getSeriesId(allSeries, allSeriesData);
      return `?subjectId=${subjectId}&categoryId=${categoryId}&priority=${priority}&seriesId=${seriesId}&week=${week}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries === "All Series" &&
      answered !== "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      let priority = allCategory;
      let answer = answered === "Not answered" ? false : true;
      return `?subjectId=${subjectId}&categoryId=${categoryId}&priority=${priority}&isAnswered=${answer}&week=${week}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory !== "All Categories" &&
      allSeries !== "All Series" &&
      answered !== "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      let seriesId = getSeriesId(allSeries, allSeriesData);
      let priority = allCategory;
      let answer = answered === "Not answered" ? false : true;
      return `?subjectId=${subjectId}&categoryId=${categoryId}&priority=${priority}&seriesId=${seriesId}&isAnswered=${answer}&week=${week}`;
    } else if (
      allSubjects === "All Subjects" &&
      allTopics === "All Topics" &&
      allCategory === "All Categories" &&
      allSeries === "All Series" &&
      answered !== "Answer Type"
    ) {
      let answer = answered === "Not answered" ? false : true;
      return `?isAnswered=${answer}&week=${week}`;
    } else if (
      allSubjects !== "All Subjects" &&
      allTopics !== "All Topics" &&
      allCategory === "All Categories" &&
      allSeries !== "All Series" &&
      answered !== "Answer Type"
    ) {
      let subjectId = getSubjectId(allSubjects, subjectData);
      let categoryId = getCategoryId(allTopics, categoryData);
      let seriesId = getSeriesId(allSeries, allSeriesData);
      let answer = answered === "Not answered" ? false : true;
      return `?subjectId=${subjectId}&categoryId=${categoryId}&seriesId=${seriesId}&isAnswered=${answer}&week=${week}`;
    }
  }
};
const getUrlForTemplate = (
  search: string,
  allSubjects: string,
  allTopics: string,
  allWeeks: string,
  subjectData: any,
  categoryData: any,
  page: number
) => {
  if (
    search === "" &&
    allWeeks === "All Weeks" &&
    allSubjects === "All Subjects" &&
    allTopics === "All Topics"
  ) {
    return `${Utils.endPoints.getTemplates}?pageNo=${page}&limit=20`;
  } else if (
    search !== "" &&
    allWeeks === "All Weeks" &&
    allSubjects === "All Subjects" &&
    allTopics === "All Topics"
  ) {
    return `${Utils.endPoints.getTemplates}?pageNo=${page}&limit=20&searchKey=${search}`;
  } else if (
    search === "" &&
    allWeeks !== "All Weeks" &&
    allSubjects !== "All Subjects" &&
    allTopics !== "All Topics"
  ) {
    let subjectId = getSubjectId(allSubjects, subjectData);
    let categoryId = getCategoryId(allTopics, categoryData);
    return `${Utils.endPoints.getTemplates}?pageNo=${page}&limit=20&week=${allWeeks}&subjectId=${subjectId}&categoryId=${categoryId}`;
  } else if (
    search === "" &&
    allWeeks !== "All Weeks" &&
    allSubjects === "All Subjects" &&
    allTopics === "All Topics"
  ) {
    return `${Utils.endPoints.getTemplates}?pageNo=${page}&limit=20&week=${allWeeks}`;
  } else if (
    search === "" &&
    allWeeks === "All Weeks" &&
    allSubjects !== "All Subjects" &&
    allTopics === "All Topics"
  ) {
    let subjectId = getSubjectId(allSubjects, subjectData);
    return `${Utils.endPoints.getTemplates}?pageNo=${page}&limit=20&subjectId=${subjectId}`;
  } else if (
    search === "" &&
    allWeeks === "All Weeks" &&
    allSubjects !== "All Subjects" &&
    allTopics !== "All Topics"
  ) {
    let subjectId = getSubjectId(allSubjects, subjectData);
    let categoryId = getCategoryId(allTopics, categoryData);

    return `${Utils.endPoints.getTemplates}?pageNo=${page}&limit=20&subjectId=${subjectId}&categoryId=${categoryId}`;
  } else if (
    search === "" &&
    allWeeks !== "All Weeks" &&
    allSubjects !== "All Subjects" &&
    allTopics === "All Topics"
  ) {
    let subjectId = getSubjectId(allSubjects, subjectData);
    return `${Utils.endPoints.getTemplates}?pageNo=${page}&limit=20&week=${allWeeks}&subjectId=${subjectId}`;
  } else if (
    search !== "" &&
    allWeeks !== "All Weeks" &&
    allSubjects === "All Subjects" &&
    allTopics === "All Topics"
  ) {
    return `${Utils.endPoints.getTemplates}?pageNo=${page}&limit=20&week=${allWeeks}&searchKey=${search}`;
  } else if (
    search !== "" &&
    allWeeks === "All Weeks" &&
    allSubjects !== "All Subjects" &&
    allTopics === "All Topics"
  ) {
    let subjectId = getSubjectId(allSubjects, subjectData);
    return `${Utils.endPoints.getTemplates}?pageNo=${page}&limit=20&subjectId=${subjectId}&searchKey=${search}`;
  } else if (
    search !== "" &&
    allWeeks === "All Weeks" &&
    allSubjects !== "All Subjects" &&
    allTopics !== "All Topics"
  ) {
    let subjectId = getSubjectId(allSubjects, subjectData);
    let categoryId = getCategoryId(allTopics, categoryData);
    return `${Utils.endPoints.getTemplates}?pageNo=${page}&limit=20&subjectId=${subjectId}&categoryId=${categoryId}&searchKey=${search}`;
  } else if (
    search !== "" &&
    allWeeks !== "All Weeks" &&
    allSubjects !== "All Subjects" &&
    allTopics === "All Topics"
  ) {
    let subjectId = getSubjectId(allSubjects, subjectData);
    return `${Utils.endPoints.getTemplates}?pageNo=${page}&limit=20&week=${allWeeks}&subjectId=${subjectId}&searchKey=${search}`;
  } else if (
    search !== "" &&
    allWeeks !== "All Weeks" &&
    allSubjects !== "All Subjects" &&
    allTopics !== "All Topics"
  ) {
    let subjectId = getSubjectId(allSubjects, subjectData);
    let categoryId = getCategoryId(allTopics, categoryData);
    return `${Utils.endPoints.getTemplates}?pageNo=${page}&limit=20&week=${allWeeks}&subjectId=${subjectId}&categoryId=${categoryId}&searchKey=${search}`;
  }
};

const initializeFilterValaues = () => {
  let filterObj = {
    allSubjects: "All Subjects",
    allTopics: "All Topics",
    allCategory: "All Categories",
    allSeries: "All Series",
    allWeeks: "All Weeks",
    answered: "Answer Type",
  };
  const filterValues = localStorage.getItem("filterValues");
  if (filterValues) {
    localStorage.removeItem("filterValues");
  }
  localStorage.setItem("filterValues", JSON.stringify(filterObj));
};

const getfilterValues = (type: string) => {
  const filterValues = localStorage.getItem("filterValues") || null;
  if (filterValues) {
    return JSON.parse(filterValues)[type];
  }
};

const saveFilterValues = (type?: string, value?: string) => {
  const filterValues = localStorage.getItem("filterValues") || null;
  if (filterValues && type) {
    localStorage.setItem(
      "filterValues",
      JSON.stringify({
        ...JSON.parse(filterValues),
        [type]: value,
      })
    );
    return;
  } else if (filterValues && !type) {
    return;
  }
  let filterObj = {
    allSubjects: "All Subjects",
    allTopics: "All Topics",
    allCategory: "All Categories",
    allSeries: "All Series",
    allWeeks: "All Weeks",
    answered: "Answer Type",
  };
  localStorage.setItem("filterValues", JSON.stringify(filterObj));
};

const checkCustomFilter = (val?: boolean) => {
  const isFilter = localStorage.getItem("isFilter") || "";
  if (isFilter && (val === true || val === false)) {
    localStorage.setItem("isFilter", JSON.stringify(val));
  } else if (isFilter) {
    return isFilter;
  } else {
    localStorage.setItem("isFilter", JSON.stringify(false));
  }
};

const convertHtmlStringToEditorState = (htmlString: string) => {
  const blockFromHTML = htmlToDraft(htmlString);
  return EditorState.createWithContent(
    ContentState.createFromBlockArray(
      blockFromHTML.contentBlocks,
      blockFromHTML.entityMap
    )
  );
};

const convertEditorStateToHtmlString = (editorState: any) => {
  return draftToHtml(convertToRaw(editorState.getCurrentContent())).toString();
};

export const ConstFunction = {
  getMonth,
  getDate,
  setFilterData,
  getTimeStamp,
  getUrl,
  getUrlForTemplate,
  getfilterValues,
  saveFilterValues,
  checkCustomFilter,
  getSubjectId,
  getCategoryId,
  getSeriesId,
  initializeFilterValaues,
  convertEditorStateToHtmlString,
  convertHtmlStringToEditorState,
};
