const ActionName = {
  BACKDROP: "backdrop",
  LOADING: "loading",
  TOTAL_COUNT: "totalCount",
  LOGIN: "login",
  FORGOT_PASSWORD: "forgotPassword",
  TOGGLE_SIDENAV: "toggleSidenav",
  PROFILE: "profile",
  HEADER_DATA: "headerdata",
  RESET_PASSWORD: "resetPassword",
  RESOURCE: "resource",
  RESOURCES_VIEW_ALL: "resourcesViewAll",
  RESOURCES_DETAILS: "resourcesDetails",
  RESOURCES_SEARCH: "resourcesSearch",
  RESOURCES_FILTERS: "resourcesFilters",
  ADD_TODO: "addTodo",
  GET_WEEK: "getWeek",
  GET_SUBJECTS: "getSubject",
  GET_CATEGORY: "getCategory",
  ALL_TASK: "allTask",
  TASK_DETAILS: "taskDetails",
  OVERDUE: "overdue",
  CHECKLIST: "checklist",
  TIME_LIMIT: "timeLimit",
  CUSTOM_RANGE: "customRange",
  PRACTICE_QUESTION: "practiceQuestion",
  ALL_SUBJECT_DROPDOWN: "allSubjectDropdown",
  ALL_TOPICS_DROPDOWN: "allTopicsDropdown",
  ALL_CATEGORIES_DROPDOWN: "allCategoriesDropdown",
  ALL_SERIES_DROPDOWN: "allSeriesDropdown",
  ANSWERED_DROPDOWN: "answeredDropdown",
  GET_TEMPLATES: "getTemplates",
  CREATE_TEMPLATE_SELECT: "createTemplateSelect",
  TEMPLATE_DETAIL: "templateDetail",
  TIME_MANAGEMENT_DATA: "timeManagementData",
  PRACTICE_QUESTION_DETAILS: "priceQuestionDetails",
  PRACTICE_ANSWER_SCORE: "practiceAnwerUpdate",
  DASHBOARD_DATA: "dashboardData",
  DASHBOARD_SUBJECT_DETAIL: "dashboard_subject_details",
  NOTIFICATION_DATA: "notificationData",
  PRACTICE_EXAM_RESULTS: "practiceExamResults",
  SELF_ASSESSMENT: "selfAssessment",
  SETLOADERVALUE: "setLoaderValue"
};
export default ActionName;
