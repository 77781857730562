const Pathname = {
  LOGIN: "/",
  FORGOT_PASSWORD: "/forgotpassword",
  RESET_PASSWORD: "/reset-password",
  STUDY_PLANNER: "/studyplanner",
  MY_PROGRESS: "/myprogress",
  TIMETABLE: "/timetable",
  PRACTICE: "/practice",
  RESOURCE: "/resources",
  MY_TEMPLATE: "/mytemplates",
  SETTINGS: "/settings",
  TOPICS: "/myprogress/topics",
  RESOURCES_DOCUMENTS: "/resources/documents",
  RESOURCES_SEMINARS: "/resources/seminars",
  RESOURCES_EXAMANNOUNCEMENTS: "/resources/examannouncements",
  PRACTICE_QUESTION: "/practice/:questionId",
  NOTIFICATION: "/notifications",
};
export default Pathname;
